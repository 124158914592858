
const particlesVertexShader =  `
    uniform vec2 uResolution;
    uniform sampler2D uPictureTexture;
    uniform float uParticleSize;
    uniform float uTime;
    uniform vec3 uPointer;
    
    attribute float aIntensity;
    attribute float aAngle;

    varying vec3 vColor;

    float random2D(vec2 value)
    {
        return fract(sin(dot(value.xy, vec2(12.9898,78.233))) * 43758.5453123);
    }

    void main()
    {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);

        // Glitch
        float glitchTime = uTime - sin(modelPosition.y / 5.) - sin(modelPosition.x / 5.);
        float glitchStrength = sin(glitchTime) + abs(sin(glitchTime * 3.45)) +  abs(sin(glitchTime * 8.76));
        glitchStrength /= 4.;
        glitchStrength = smoothstep(0.4, 1.0, glitchStrength);
        glitchStrength *= 0.2;

        // modelPosition.x += (random2D(modelPosition.xz + uTime * 1.5) - 1.5) * glitchStrength; // Water Effect
        // modelPosition.y += (random2D(modelPosition.zx + uTime * 1.5) - 1.5) * glitchStrength; // Water Effect
        modelPosition.x += (random2D(modelPosition.xz + uTime * 1.5) - .75) * glitchStrength;
        modelPosition.y += (random2D(modelPosition.zx + uTime * 1.5) - .75) * glitchStrength;



        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectedPosition = projectionMatrix * viewPosition;
        gl_Position = projectedPosition;

        // Picture
        float pictureIntensity = texture(uPictureTexture, uv).r;

        // Point size
        // gl_PointSize = 0.3 * uResolution.y; Initial
        gl_PointSize = uParticleSize * pictureIntensity * uResolution.y;


        gl_PointSize *= (1.0 / - viewPosition.z);

        // Varyings
        vColor = vec3(pow(pictureIntensity, 3.0));
        vColor.z *= cos(uTime * 4.) + 3.;
    }
`

export default particlesVertexShader