import React from 'react';
import { motion } from 'framer-motion';
import { FaHtml5, FaCss3, FaJs, FaReact, FaPhp } from 'react-icons/fa';
import { SiFirebase, SiMysql, SiThreedotjs } from "react-icons/si";
import { RiSupabaseLine } from "react-icons/ri";

import Aboutscene from './Aboutscene';
import '../assets/About.css'


const About: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className='framermotion'
    >
      <Aboutscene/>
      <h2 style={{textAlign:"center"}}>About me</h2>
      <p style={{textAlign:"center",marginTop:"1em"}}>I'm a French web developer who enjoys alternating between building robust back-ends and crafting interactive, creative UIs.</p>
      <h3 style={{marginTop:"2em"}}>Main Skills</h3>
      <div className="skills">
        <span data-skill='JavaScript'>
            <FaJs  title="JavaScript" />
        </span>
        <span data-skill='React'>
            <FaReact  title="React" />
        </span>
        <span data-skill='Three.js'>
            <SiThreedotjs title='Three.js'/>
        </span>
        <span data-skill='PHP'>
            <FaPhp  title="PHP" />
        </span>
        <span data-skill='mySQL'>
            <SiMysql  title='mySQL'/>
        </span>
        <span data-skill='Supabase'>
            <RiSupabaseLine  title='Supabase'/>
        </span>
        <span data-skill='Firebase'>
            <SiFirebase  title='Firebase'/>
        </span>
        <span data-skill='CSS3'>
            <FaCss3  title="CSS3" />
        </span>
        <span data-skill='HTML5'>
            <FaHtml5  title="HTML5" />
        </span>
      </div>
    </motion.div>
  );
}

export default About;