'use client';

import { FC, useEffect, useRef } from 'react';
import '../assets/2DvintageSpaceshooter.css'

const GamishHeader: FC = () => {
    const hasInit = useRef(false)

    useEffect(() => {
        if(!hasInit || !hasInit.current)
        {
            const canvas = document.getElementById('canvas') as HTMLCanvasElement|null 
            if(canvas)
            {
                canvas.height =  window.innerHeight

                if(
                    ('ontouchstart' in window) ||
                    (navigator.maxTouchPoints > 0) 
                ){
                    canvas.height *= 0.7
                }

                canvas.width = window.innerWidth
            }
           
            
            const library = document.createElement('script')
            library.src = "./2D-vintage-spaceshooter/spaceship_shooter_particles.js"
            document.body.appendChild(library)
            hasInit.current = true
        }
    }, [])
    return(
        <section id='gamish_header'>
            <section id="particle-image">
                <canvas id="canvas" className="particle-image-canvas-el"></canvas>
            </section>
            <div id="pad">
                <button id = "joystick_left" className='spacebar' type = "button" >
                    <span>Shoot</span>
                </button>
                <div className="arrow_container">
                    <div id = "up" className="arrow up"></div>
                    <div id = "left" className="arrow left"></div>
                    <div id = "right" className="arrow right"></div>
                    <div id = "down" className="arrow down"></div>
                </div>
            </div>
        </section>
    )
}

export default GamishHeader;