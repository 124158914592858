'use client';

import { FC, useEffect, useRef } from 'react';
import '../assets/ParticleImage.css'

const ParticleImage: FC = () => {
    const hasInit = useRef(false)
    const particle_canvas = useRef(null)


    useEffect(() => {
        if(!hasInit || !hasInit.current)
        {
            if(window.innerWidth < 800 && particle_canvas.current != null)
            {
                const htmlref : HTMLElement = particle_canvas.current
                htmlref.dataset.paramsSrc = "./particle-image/params_mobile.json"
            }


            if( (!hasInit || !hasInit.current))
            {
                console.log("init")
                const library = document.createElement('script')
                library.src = "./particle-image/particle-image.js"
                document.body.appendChild(library)
                hasInit.current = true
            }

        }
    }, [])
    return(
        <section id='particleImage'>
                <div id="particle-image" ref={particle_canvas} data-params-src={"./particle-image/params.json"}></div>
        </section>
    )
}

export default ParticleImage;