import React from 'react';
import About from './About';


const Home: React.FC = () => {
  return (
    <div className="home">
      <About/>
    </div>
  );
}

export default Home;