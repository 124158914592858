import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer>
      <p style={{textAlign:"center"}}><span>&copy; 2024 Axel Croizé (Tolexia)</span><span className='dot'>.</span> <span>All Rights Reserved </span></p>
    </footer>
  );
}

export default Footer;