import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import ProjectDetails from './components/ProjectDetails';
import ProjectCategories from './components/ProjectCategories';
import ProjectListConventional from './components/ProjectListConventional';
import ProjectListCreative from './components/ProjectListCreative';
import ProjectListGamish from './components/ProjectListGamish';
import About from './components/About';
import Contact from './components/Contact';
import "./App.css"

import { Analytics } from "@vercel/analytics/react"

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Analytics/>
        <Header />
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project/:id" element={<ProjectDetails />} />
            <Route path="/projects" element={<ProjectCategories />} />
            <Route path="/projects/creative" element={<ProjectListCreative />} />
            <Route path="/projects/gamish" element={<ProjectListGamish />} />
            <Route path="/projects/conventional" element={<ProjectListConventional />} />
            <Route path="/projects" element={<ProjectCategories />} />
            <Route 
                path="/projects/:projectCategory/id/:projectIndex" 
                element={(<ProjectDetails />)}
            />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </AnimatePresence>
        <Footer />
      </div>
    </Router>
  );
}

export default App;