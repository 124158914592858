import '../assets/projectCategories.css'
import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useThree, useFrame, ThreeEvent } from '@react-three/fiber';
import { OrbitControls, Sphere } from '@react-three/drei';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';

import * as THREE from 'three';
import gsap from 'gsap';

const PARTICLE_COUNT = 2500;
const ATTRACTOR_COUNT = 3;

const camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 0.1, 100);
camera.position.set(0, 2, 6);  

// Vertex Shader
const vertexShader = `
  attribute vec3 velocity;
  attribute float particleMass;
  
  uniform float scale;
  
  varying vec3 vVelocity;
  varying float vParticleMass;
  
  void main() {
    vVelocity = velocity;
    vParticleMass = particleMass;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
    gl_PointSize = scale * particleMass * (0.25 / length(mvPosition.xyz));
  }
`;

// Fragment Shader
const fragmentShader = `
  uniform vec3 colorA;
  uniform vec3 colorB;
  uniform float maxSpeed;
  
  varying vec3 vVelocity;
  varying float vParticleMass;
  
  void main() {
    float distanceToCenter = length(gl_PointCoord - vec2(0.5));
    if(distanceToCenter > 0.5)
            discard;

    float speed = length(vVelocity);
    float colorMix = smoothstep(0.0, 0.125, speed / maxSpeed);
    vec3 color = mix(colorA, colorB, colorMix) * 1.5;
    gl_FragColor = vec4(color, 1.0);
  }
`;

interface Uniforms {
  attractorMass: { value: number };
  particleGlobalMass: { value: number };
  timeScale: { value: number };
  spinningStrength: { value: number };
  maxSpeed: { value: number };
  gravityConstant: { value: number };
  velocityDamping: { value: number };
  scale: { value: number };
  boundHalfExtent: { value: number };
  colorA: { value: THREE.Color };
  colorB: { value: THREE.Color };
  attractorPositions: { value: THREE.Vector3[] };
  attractorRotationAxes: { value: THREE.Vector3[] };
} 

interface PlanetWithRingProps {
    planetRadius?: number;
    planetColor?: number;
    ringInnerRadius?: number;
    ringOuterRadius?: number;
    ringColor?: number;
    planetSegments?: number;
    ringSegments?: number;
    quaternion: THREE.Quaternion;
}

const PlanetWithRing: React.FC<PlanetWithRingProps> = ({
    planetRadius,
    planetColor,
    ringInnerRadius = 0,
    ringOuterRadius = 0,
    ringColor,
    planetSegments = 32,
    ringSegments = 32,
    quaternion
}) => {

      // Extraire l'axe de rotation du quaternion
      const rotationAxis = new THREE.Vector3(0, 1, 0).applyQuaternion(quaternion);
      
    // console.log("quaternion", quaternion)
    // console.log("rotationAxis", rotationAxis)
    // console.log("ringInnerRadius", ringInnerRadius)

      // Calculer le quaternion pour l'anneau
      const ringQuaternion = new THREE.Quaternion();
      
        // Trouver le vecteur perpendiculaire à l'axe de rotation
        const perpendicular = new THREE.Vector3(1, 0, 0)
            .cross(rotationAxis)
            .normalize();
        
        // Créer un quaternion qui aligne l'anneau perpendiculairement à l'axe de rotation
        ringQuaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), perpendicular);
  
    return (
        <group>
            {/* Planète */}
            <points>
                <sphereGeometry args={[planetRadius, planetSegments, planetSegments]} />
                <pointsMaterial color={planetColor} size={0.03} />
            </points>
            
            {/* Anneau */}
            <mesh quaternion={ringQuaternion}>
                <ringGeometry args={[ringInnerRadius, ringOuterRadius, ringSegments]} />
                <meshBasicMaterial 
                    color={ringColor} 
                    side={THREE.DoubleSide}
                    transparent={true}
                    opacity={0.8}
                    wireframe={true}
                />
            </mesh>
        </group>
    );
};


// Définition des attracteurs
const attractorMeshes = [
    {
        component: PlanetWithRing,
        props: {
            planetRadius: 0.15,
            planetColor: 0xFFAA00,
            ringInnerRadius: 0.21,
            ringOuterRadius: 0.27,
            ringColor: 0xEEEEEE
        }
    },
    {
        component: 'points',
        props: {
            geometry: new THREE.TorusGeometry(0.15, 0.05, 32, 32),
            material: new THREE.PointsMaterial({ color: 0x0099FF, size: 0.03 })
        }
    },
    {
        component: 'mesh',
        props: {
            geometry: new THREE.OctahedronGeometry(0.15, 2).rotateX(Math.PI/2),
            material: new THREE.MeshBasicMaterial({ color: 0x00EE00, wireframe: true })
        }
    }
];

interface AttractorSpheresProps {
    attractorPositions: THREE.Vector3[];
    attractorRotationAxes: THREE.Vector3[];
}

interface CameraControlsProps {
    onAttractorClick: (position: THREE.Vector3) => void;
  }

  function updateAttractorsNodePositions(
    camera: THREE.Camera, 
    attractorPositions: THREE.Vector3[], 
    size: { width: number, height: number }, 
    viewport: { dpr: number }
  ) {
    const sizes = {
      width: size.width,
      height: size.height,
      pixelRatio: Math.min(viewport.dpr, 2)
    }

    for (let i = 0; i < ATTRACTOR_COUNT; i++) {
      const screenPosition = attractorPositions[i].clone()
      screenPosition.project(camera)
      const translateX = screenPosition.x * sizes.width * 0.5
      const translateY = - screenPosition.y * sizes.height * 0.5
  
      const node: HTMLElement|null = document.querySelector(attractorNodes[i])
      if(node){
        node.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
      }
    }
  }
  
  const CameraControls: React.FC<CameraControlsProps> = () => {
    const controlsRef = useRef<OrbitControlsImpl>(null);
    const set = useThree(state => state.set);
  
    useEffect(() => {
        if (controlsRef.current) {
          set({ controls: controlsRef.current });
        }
      }, [set]);
  
    return <OrbitControls ref={controlsRef} enableDamping />;
  };

const AttractorSpheres: React.FC<AttractorSpheresProps> = ({ 
    attractorPositions, 
    attractorRotationAxes 
}) => {
    const attractorsRefs = useRef<(THREE.Group | null)[]>([]);
    const { camera, size, viewport } = useThree();
    const isAnimating = useRef(false);
    const controls = useThree(state => state.controls) as OrbitControlsImpl;

    const handleClick = (event: ThreeEvent<MouseEvent>, index: number) => {
        event.stopPropagation();
        if (isAnimating.current) return;
        
        const position = attractorPositions[index];
        handleAttractorClick(position, index);
    };


    const handleAttractorClick = (position: THREE.Vector3, index: number) => {
        if (isAnimating.current) return;
        isAnimating.current = true;

        console.log("index", index)
        if (controls) {
            console.log("disabled controls");
            controls.enabled = false;

            
            const targetPosition = position.clone().add(new THREE.Vector3(0.5, 0.5, 0.5));
            const lookAtPosition = position.clone();

            // Animer à la fois la caméra ET la target des controls
            gsap.timeline()
              .to(camera.position, {
                x: targetPosition.x,
                y: targetPosition.y,
                z: targetPosition.z + 2,
                duration: 2,
                ease: "power3.inOut",
              })
              .to(controls.target, {
                x: lookAtPosition.x,
                y: lookAtPosition.y,
                z: lookAtPosition.z,
                duration: 2,
                ease: "power3.inOut",
                onUpdate: () => {
                  camera.lookAt(controls.target);
                  updateAttractorsNodePositions(camera, attractorPositions, size, viewport);
                },
                onComplete: () => {
                  isAnimating.current = false;
                  if (controls) {
                    console.log("enabled controls");
                    controls.enabled = true;
                  }
                }
              }, "<"); // Le "<" fait démarrer cette animation en même temps que la précédente
        }
    };


    useFrame((_, delta) => {
        attractorsRefs.current.forEach((attractor, i) => {
            if (attractor) {
                attractor.rotateOnWorldAxis(attractorRotationAxes[i], -delta);
            }
        });
    });


    return (
        <>
            {attractorMeshes.map((meshData, i) => {
                const AttractorComponent = meshData.component;
                return (
                    <group
                        key={i}
                        ref={(el) => {
                            if (attractorsRefs.current.length <= i) {
                                attractorsRefs.current.push(el);
                            } else {
                                attractorsRefs.current[i] = el;
                            }
                        }}
                        position={attractorPositions[i]}
                        rotation={ meshData.props.geometry?.type == "BoxGeometry" ? new THREE.Euler(1,0,0) : new THREE.Euler(0,0,0)}
                       
                    >
                        <Sphere scale={[0.15,0.15,0.15]} visible={false}  onClick={(e) => handleClick(e, i)} />
                        <AttractorComponent  quaternion={new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 1, 0), attractorRotationAxes[i])} {...meshData.props} />
                    </group>
                );
            })}
        </>
    );
};

const attractorPositions =  [
    new THREE.Vector3(-0.5, 0.25, 0),
    new THREE.Vector3(0.5, 0, -0.5),
    new THREE.Vector3(0, -0.25, 0.5),
] 
const attractorRotationAxes = [
    new THREE.Vector3(0, 1, 0),
    new THREE.Vector3(0, -0.5, 1).normalize(),
    new THREE.Vector3(0, 1, 0).normalize(),
]
const attractorNodes = [
    '.point-0',
    '.point-1',
    '.point-2'
]
interface Uniforms {
    attractorMass: THREE.IUniform<number>;
    particleGlobalMass: THREE.IUniform<number>;
    timeScale: THREE.IUniform<number>;
    spinningStrength: THREE.IUniform<number>;
    maxSpeed: THREE.IUniform<number>;
    gravityConstant: THREE.IUniform<number>;
    velocityDamping: THREE.IUniform<number>;
    scale: THREE.IUniform<number>;
    boundHalfExtent: THREE.IUniform<number>;
    colorA: THREE.IUniform<THREE.Color>;
    colorB: THREE.IUniform<THREE.Color>;
    attractorPositions: THREE.IUniform<THREE.Vector3[]>;
    attractorRotationAxes: THREE.IUniform<THREE.Vector3[]>;
    [uniform: string]: THREE.IUniform; // Signature d'index ajoutée
  }
const ParticleScene: React.FC = () => {
  const [uniforms] = useState<Uniforms>({
    attractorMass: { value: 1e7 },
    particleGlobalMass: { value: 1e4 },
    timeScale: { value: 0.01 },
    spinningStrength: { value: 4 },
    maxSpeed: { value: 2 },
    gravityConstant: { value: 6.67e-11 },
    velocityDamping: { value: 0.7 },
    scale: { value: 0.008 },
    boundHalfExtent: { value: 8 },
    colorA: { value: new THREE.Color('#5900ff') },
    colorB: { value: new THREE.Color('#ffa575') },
    attractorPositions: { value: attractorPositions },
    attractorRotationAxes: { value: attractorRotationAxes }
  });



  const positions = new Float32Array(PARTICLE_COUNT * 3);
    const velocities = new Float32Array(PARTICLE_COUNT * 3);
    const particleMasses = new Float32Array(PARTICLE_COUNT);

    for (let i = 0; i < PARTICLE_COUNT; i++) {
      const i3 = i * 3;
      positions[i3] = (Math.random() - 0.5) * 5;
      positions[i3 + 1] = (Math.random() - 0.5) * 0.2;
      positions[i3 + 2] = (Math.random() - 0.5) * 5;

      const phi = Math.random() * Math.PI * 2;
      const theta = Math.random() * Math.PI;
      const r = 0.05;
      velocities[i3] = r * Math.sin(phi) * Math.sin(theta);
      velocities[i3 + 1] = r * Math.cos(phi);
      velocities[i3 + 2] = r * Math.sin(phi) * Math.cos(theta);

      particleMasses[i] = (Math.random() * 0.75 + 0.25) * uniforms.particleGlobalMass.value;
    }

    const particleGeometry = new THREE.BufferGeometry();
    particleGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    particleGeometry.setAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
    particleGeometry.setAttribute('particleMass', new THREE.BufferAttribute(particleMasses, 1));

    const { camera, size, viewport } = useThree();
  

    useEffect( () => {
        gsap.to(camera.position, {
            duration:2,
            y: 1,
            z: screen.orientation.type.match("portrait") ? 6 : 3,
            ease:"power4.out",
            onUpdate: () => {
                // Mettre à jour les positions des nœuds pendant l'animation initiale
                updateAttractorsNodePositions(camera, attractorPositions, size, viewport);
              }
        })
        // camera.position.set(3, 4, 6);  
    }, [])


  useFrame(() => {
    const positions = particleGeometry.attributes.position.array as Float32Array;
    const velocities = particleGeometry.attributes.velocity.array as Float32Array;

    updateAttractorsNodePositions(camera, attractorPositions, size, viewport);

    for (let i = 0; i < PARTICLE_COUNT; i++) {
        const i3 = i * 3;
    
        // Calculate forces
        let fx = 0, fy = 0, fz = 0;
        for (let j = 0; j < ATTRACTOR_COUNT; j++) {
          const dx = attractorPositions[j].x - positions[i3];
          const dy = attractorPositions[j].y - positions[i3 + 1];
          const dz = attractorPositions[j].z - positions[i3 + 2];
          const distSq = dx * dx + dy * dy + dz * dz;
          const dist = Math.sqrt(distSq);
    
          // Gravity
          const gravityStrength = uniforms.attractorMass.value * particleMasses[i] * uniforms.gravityConstant.value / distSq;
          const forceMag = gravityStrength / dist;
          fx += forceMag * dx;
          fy += forceMag * dy;
          fz += forceMag * dz;
    
          // Spinning
          const spinningStrength = gravityStrength * uniforms.spinningStrength.value;
          const spinX = attractorRotationAxes[j].y * dz - attractorRotationAxes[j].z * dy;
          const spinY = attractorRotationAxes[j].z * dx - attractorRotationAxes[j].x * dz;
          const spinZ = attractorRotationAxes[j].x * dy - attractorRotationAxes[j].y * dx;
          fx += spinningStrength * spinX;
          fy += spinningStrength * spinY;
          fz += spinningStrength * spinZ;
        }
    
        // Update velocity
        velocities[i3] += fx * uniforms.timeScale.value;
        velocities[i3 + 1] += fy * uniforms.timeScale.value;
        velocities[i3 + 2] += fz * uniforms.timeScale.value;
    
        // Apply speed limit
        const speedSq = velocities[i3] * velocities[i3] + velocities[i3 + 1] * velocities[i3 + 1] + velocities[i3 + 2] * velocities[i3 + 2];
        if (speedSq > uniforms.maxSpeed.value * uniforms.maxSpeed.value) {
          const speedFactor = uniforms.maxSpeed.value / Math.sqrt(speedSq);
          velocities[i3] *= speedFactor;
          velocities[i3 + 1] *= speedFactor;
          velocities[i3 + 2] *= speedFactor;
        }
    
        // Apply damping
        const damping = 1 - uniforms.velocityDamping.value;
        velocities[i3] *= damping;
        velocities[i3 + 1] *= damping;
        velocities[i3 + 2] *= damping;
    
        // Update position
        positions[i3] += velocities[i3] * uniforms.timeScale.value;
        positions[i3 + 1] += velocities[i3 + 1] * uniforms.timeScale.value;
        positions[i3 + 2] += velocities[i3 + 2] * uniforms.timeScale.value;
    
        // Box loop
        const halfExtent = uniforms.boundHalfExtent.value;
        positions[i3] = (positions[i3] + halfExtent) % (2 * halfExtent) - halfExtent;
        positions[i3 + 1] = (positions[i3 + 1] + halfExtent) % (2 * halfExtent) - halfExtent;
        positions[i3 + 2] = (positions[i3 + 2] + halfExtent) % (2 * halfExtent) - halfExtent;
      }
    
      particleGeometry.attributes.position.needsUpdate = true;
      particleGeometry.attributes.velocity.needsUpdate = true;

    }
  );

  return (
    <>
      <AttractorSpheres attractorPositions={uniforms.attractorPositions.value} attractorRotationAxes={uniforms.attractorRotationAxes.value} />
      <points geometry={particleGeometry}>
        <shaderMaterial
          attach="material"
          uniforms={uniforms}
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          transparent
          blending={THREE.AdditiveBlending}
          depthWrite={false}
        />
      </points>
    </>
  );
};

const BloomEffect: React.FC = () => {
    return (
      <EffectComposer>
        <Bloom 
          intensity={0.5} // Ajustez l'intensité selon vos préférences
          luminanceThreshold={0.3}
          luminanceSmoothing={0.99}
        />
      </EffectComposer>
    );
  };
  
  const ProjectCategories: React.FC = () => {
    
    // const viewport = {dpr: window.devicePixelRatio}
    // const resetCamera = () => {
    //     gsap.to(camera.position, {
    //       duration: 2,
    //       x: 0,
    //       y: 2,
    //       z: 6,
    //       ease: "power4.out",
    //       onUpdate: () => {
    //         // Mettre à jour les positions des nœuds pendant le reset
    //         const canvas =  document.querySelector('canvas')
    //         const width = canvas ? canvas.width : window.innerWidth
    //         const height = canvas ? canvas.height : window.innerHeight
    //         const size = {
    //             width,
    //             height
    //         }
    //         updateAttractorsNodePositions(camera, attractorPositions, size, viewport);
    //       }
    //     });
    //   };
    return (
      <section className='project_categories'>
        <Canvas camera={camera}>
          {/* <OrbitControls enableDamping /> */}
          <CameraControls onAttractorClick={() => {}} />
          <ambientLight intensity={0.5} />
          <directionalLight position={[4, 2, 0]} intensity={1.5} />
          <ParticleScene />
          <BloomEffect />
        </Canvas>
        {/* <button 
        onClick={resetCamera}
        className="reset-camera-button"
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          zIndex: 10
        }}
      >
        Reset View
      </button> */}
        <div className="point point-0 visible">
            <span tabIndex={0} className="label"  style={{"--delay": "1s" } as React.CSSProperties}>
                <span>1</span>
            </span>
            <a href="/projects/creative" className="text">
                <span>Creative Projects</span>
            </a>
        </div>
        <div className="point point-1 visible">
            <span tabIndex={0} className="label" style={{"--delay": "4s" } as React.CSSProperties}>
                <span>2</span>
            </span>
            <a href="/projects/gamish" className="text">
                <span>Gamish Projects</span>
            </a>
        </div>
        <div className="point point-2 visible" style={{"--delay": "7s" } as React.CSSProperties}>
            <span tabIndex={0} className="label">
                <span>3</span>
            </span>
            <a href="/projects/conventional" className="text">
                <span>Conventional Projects</span>
            </a>
        </div>
        <svg viewBox="146.4017 91.3578 26.0019 26" xmlns="http://www.w3.org/2000/svg" className='movable_indication'>
            <g transform="matrix(1, 0, 0, 1, 143.40367340152966, 88.35780466136043)">
                <circle cx="16" cy="16" r="4" />
                <polyline points="20 8.642 16 4.642 12 8.642" />
                <polyline points="8.596 12 4.596 16.073 8.596 20" />
                <polyline points="12 22.642 16 26.642 20 22.642" />
                <polyline points="23.596 20 27.596 16 23.596 12" />
            </g>
            </svg>
      </section>
    );
  };

export default ProjectCategories;
