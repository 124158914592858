import React from 'react';
import { motion } from 'framer-motion';
import { FiTwitter, FiMail } from "react-icons/fi";
import { LiaLinkedin } from "react-icons/lia";
import { SiGithub } from "react-icons/si";

import '../assets/Contact.css'

const Contact: React.FC = () => {

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
        <h2  className='contact_title'>Contact</h2>
        <div className="contact-container">
            <div className="contact-card-wrapper">    
                <div className="contact-card shiny-cta">
                    <div className="card-content inner">
                        <div className="contact-item" style={{"--delay": "1s" } as React.CSSProperties}>
                            <FiMail className="icon" />
                            <a href="mailto:axelcroizepro@gmail.com">axelcroizepro@gmail.com</a>
                        </div>
                        <div className="contact-item" style={{"--delay": "2s" } as React.CSSProperties}>
                            <SiGithub className="icon" />
                            <a href="https://github.com/Tolexia" target="_blank" rel="noopener noreferrer">
                                @Tolexia
                            </a>
                        </div>
                        <div className="contact-item" style={{"--delay": "3s" } as React.CSSProperties}>
                            <FiTwitter className="icon" />
                            <a href="https://x.com/Tolexia_dev" target="_blank" rel="noopener noreferrer">
                                @Tolexia_dev
                            </a>
                        </div>
                        <div className="contact-item" style={{"--delay": "4s" } as React.CSSProperties}>
                            <LiaLinkedin className="icon" />
                            <a href="https://www.linkedin.com/in/axel-croize/" target="_blank" rel="noopener noreferrer">
                                Axel Croizé
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </motion.div>
  );
}

export default Contact;