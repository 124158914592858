import projects from '../assets/projects_data.json'
import '../assets/projectList.css'
import '../assets/projectList_conventional.css'

const ProjectListConventional: React.FC = () => {
    return (
        <section className='projectlist conventional projectlist_wrapper'>
            <h1>Conventional websites</h1>
                <ul className='section-wrapper'>
                    {projects.conventional.map((project, projectIndex) => (
                        <li key={projectIndex} className="">
                            <div className="card">
                                <a href={`/projects/conventional/id/${projectIndex}`} className="card-link"></a>
                                <img src={project.thumbnail} alt="" className="image" />
                                <div className="text-wrapper">
                                    <h2 className="title">{project.title}</h2>
                                    <div className="skills">
                                        {project.skills.map((skill, skillIndex) => (
                                            <span key={skillIndex}>{skill}</span>
                                        ))}
                                    </div>
                                    {/* <div className="details-wrapper">
                                        <p className="description">{project.short_description}</p>
                                    </div> */}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
        </section>
    )
}

export default ProjectListConventional