import React from 'react';
// import { Link } from 'react-router-dom';
import '../assets/Header.css'
import { Link } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';

const Header: React.FC = () => {
	return (
		<header>
		<link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@100" rel="stylesheet" />
		<nav>
			<Link className='navitem'  title="Home"  to="/">
				<span>Home</span>
				<span className="material-symbols-outlined" aria-hidden="true">home</span>
				<svg viewBox="0 0 300 300" aria-hidden="true">
					<g>
					<text fill="currentColor">
						<textPath xlinkHref="#circlePath">Home</textPath>
					</text>
					<text fill="currentColor">
						<textPath xlinkHref="#circlePath" startOffset="50%">Home</textPath>
					</text>
					</g>
				</svg>
			</Link>
			{/* <button type="button">
				<span>About</span>
				<span className="material-symbols-outlined" aria-hidden="true">info</span>
				<svg viewBox="0 0 300 300" aria-hidden="true">
					<g>
					<text fill="currentColor">
						<textPath xlinkHref="#circlePath">About</textPath>
					</text>
					<text fill="currentColor">
						<textPath xlinkHref="#circlePath" startOffset="50%">About</textPath>
					</text>
					</g>
				</svg>
			</button> */}
			<Link className='navitem' to="/projects" >
				<span>Projects</span>
				<span className="material-symbols-outlined" aria-hidden="true">rocket_launch</span>
				<svg viewBox="0 0 300 300" aria-hidden="true">
					<g>
					<text fill="currentColor">
						<textPath xlinkHref="#circlePath">Projects</textPath>
					</text>
					<text fill="currentColor">
						<textPath xlinkHref="#circlePath" startOffset="50%">Projects</textPath>
					</text>
					</g>
				</svg>
			</Link>
			<Link className='navitem' to="/contact">
				<span>Contact</span>
				<span className="material-symbols-outlined" aria-hidden="true">email</span>
				<svg viewBox="0 0 300 300">
					<g>
					<text fill="currentColor" aria-hidden="true">
						<textPath xlinkHref="#circlePath">Contact</textPath>
					</text>
					<text fill="currentColor">
						<textPath xlinkHref="#circlePath" startOffset="50%">Contact</textPath>
					</text>
					</g>
				</svg>
			</Link>
		</nav>

		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 300" width="0" height="0">
			<defs>
			<path id="circlePath" d="M 150, 150 m -50, 0 a 50,50 0 0,1 100,0 a 50,50 0 0,1 -100,0" />
			</defs>
		</svg>
		</header>
	);
}

export default Header;